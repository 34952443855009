section{
  margin-bottom: 60px;
  margin-top: 60px;
}



.main-raised {
  margin-top: -80px;
  padding: 30px 0;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12), 0 8px 10px -5px rgba(0,0,0,.2);
}

@media (min-width:991px){
  .main-raised {
    margin: -60px 70px 0;
  }
}

.main {
  background: #fff;
  position: relative;
  z-index: 3;
}
.navbar .material-icons {
  font-size: 21px;
  display: inline-block;
  vertical-align: sub;
}

.right-img{
  position: relative;
  @media (max-width:991px){
    display: none;
  }
  .img2{
    position: absolute;
    left: -20%;
    bottom: 20%;
    width: 67%;
  }
}
.page-header{
  height: 40vh;
  &.page-header-lg{
    height: 90vh;
  }
  &.page-header-sm{
    height: 20vh;
  }
  background-position: 50%;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
}

